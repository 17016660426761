import React from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import "../../styles/styles.css";

export default function TextWithTooltip({
  hint,
  text,
  component = "p",
  className = "",
  visible = true,
}) {
  if (visible) {
    return (
      <>
        <Typography
          variant="p"
          component={component}
          className={`text-with-tooltip ${className}`}
        >
          {text}
          <Tooltip title={hint} arrow>
            <Button aria-label={hint}>?</Button>
          </Tooltip>
        </Typography>
      </>
    );
  } else {
    return <></>;
  }
}
