import React from "react";
import "../styles/styles.css";

function Header(props) {
  function handleClick() {
    props.navigate("/");
  }

  return (
    <div data-testid="HeaderCopyId" className="header-container">
      <img
        id="header-logo"
        onClick={handleClick}
        className="header-logo"
        src={process.env.PUBLIC_URL + "/MHRA-logo.png"}
        alt="MHRA header logo"
      />
    </div>
  );
}

export default Header;
