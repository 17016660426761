import axios from "axios";

export default async function searchDatabase(
  searchTerm,
  setRecievedData,
  setGlobalReturnedData,
  advApiRoute,
  basicApiRoute,
  setDatabaseSearched
) {
  let returnedData = "";
  let splitParam = searchTerm.split("&&");
  if (splitParam[0] === "advSearch=") {
    apifuncadv();
  } else {
    apifunc();
  }

  async function apifuncadv() {
    const data = {
      searchTerm: {
        manufacturerName: splitParam[1],
        referenceNumber: splitParam[2],
        deviceName: splitParam[3],
        deviceType: splitParam[4],
        gmdnCode: splitParam[5],
      },
    };
    try {
      const postRequest = await axios.post(advApiRoute, data);
      returnedData = postRequest.data;
      setRecievedData(postRequest.data);
      setGlobalReturnedData(postRequest.data);
      setDatabaseSearched(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function apifunc() {
    const data = {
      searchTerm: searchTerm,
    };
    try {
      const postRequest = await axios.post(basicApiRoute, data);
      returnedData = postRequest.data;
      setRecievedData(postRequest.data);
      setGlobalReturnedData(postRequest.data);
      setDatabaseSearched(true);
    } catch (err) {
      console.log(err);
    }
  }

  return returnedData;
}
