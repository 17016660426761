import React from "react";
import "../styles/styles.css";

function FeedbackBanner() {
  return (
    <div className="phase-banner">
      <p className="phase-banner-content">
        <strong className="feedback-banner-tag phase-banner-content-tag">
          beta
        </strong>
        <span className="phase-banner-text">
          This is a new service – your{" "}
          <a
            id="feedback-banner-link"
            data-testid="feedbackBanner"
            className="feedback-banner-link"
            href="mailto:PARD.feedback@mhra.gov.uk?subject=PARD%20Feedback"
          >
            feedback
          </a>{" "}
          will help us to improve it.
        </span>
      </p>
    </div>
  );
}

export default FeedbackBanner;
