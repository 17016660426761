import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_AI_KEY || "InstrumentationKey=e8db2ed1-a022-47a8-826c-de7e0bfda549;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
