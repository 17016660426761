import React from "react";
import { TextField } from "@mui/material";
import TextWithTooltip from "./shared/TextWithTooltip";
import "../styles/styles.css";

export default function SearchField({
  hint,
  text,
  id,
  onChangeEvent,
  value,
  helperText,
  error,
  dataCy,
  advancedSearch,
}) {
  return (
    <>
      <TextWithTooltip hint={hint} text={text} />
      <TextField
        variant="outlined"
        helperText={helperText || ""}
        placeholder="Search"
        label={value ? "" : text.slice(0, -1)}
        InputLabelProps={{ shrink: false }}
        id={id}
        data-cy={dataCy}
        autoComplete="off"
        value={value}
        error={error}
        onChange={(event) => {
          onChangeEvent(event.target.value);
        }}
        className={advancedSearch ? "search-field-advanced" : "search-field"}
      ></TextField>
    </>
  );
}
