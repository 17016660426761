import React from "react";

function Pagination({
  currentPage,
  onPageChange,
  pageSize,
  totalItems,
  visiblePageCount = 3,
}) {
  const pageCount = Math.ceil(totalItems / pageSize);
  const pageNumbers = [];
  for (let i = 0; i < pageCount; i++) {
    if (
      i === 0 ||
      i === pageCount - 1 ||
      (i >= currentPage - visiblePageCount &&
        i <= currentPage + visiblePageCount)
    ) {
      pageNumbers.push(i);
    }
  }

  return (
    <div className="pagination">
      {currentPage > 0 && (
        <button
          className="pagination-button prev-page"
          onClick={() => onPageChange(currentPage - 1)}
        >
          Previous
        </button>
      )}
      {pageNumbers.map((pageNum) => (
        <button
          key={pageNum}
          className={`pagination-button page-number ${
            pageNum === currentPage ? "active" : ""
          }`}
          onClick={() => onPageChange(pageNum)}
        >
          {pageNum + 1}
        </button>
      ))}
      {currentPage < pageCount - 1 && (
        <button
          className="pagination-button next-page"
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next
        </button>
      )}
    </div>
  );
}

export default Pagination;
