const DeviceSearchHeaders = [
  {
    label: "GMDN® Code",
    key: "GMDN_CODE",
  },
  {
    label: "GMDN® Term",
    key: "GMDN_TERM_NAME",
  },
  {
    label: "Medical Device Risk Classification",
    key: "DEVICE_SUB_TYPE_DESC",
  },
];

export default DeviceSearchHeaders;
