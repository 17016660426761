import React, { useEffect, useState } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Grid } from "@mui/material";
import Search from "./components/Search";
import Homepage from "./components/Homepage";
import DeviceSearch from "./components/DeviceSearch";
import ManufacturerSearch from "./components/ManufacturerSearch";
import ManufacturerDetails from "./components/ManufacturerDetails";
import DeviceDetails from "./components/DeviceDetails";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Loading from "./components/Loading";
import Accessibility from "./components/Accessibility";
import Faq from "./components/Faq";
import TwoLinks from "./components/TwoLinks";
import FeedbackBanner from "./components/FeedbackBanner";

import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./ApplicationInsights";

function App() {
  const [selectedManu, setSelectedManu] = useState("");
  const [selectedDev, setSelectedDev] = useState("");
  const [selectedType, setSelectedType] = useState("devices");
  const [isMobile, setIsMobile] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const [returnedDevices, setReturnedDevices] = useState("");
  const [returnedManufacturers, setReturnedManufacturers] = useState("");
  const [advancedToggled, setAdvancedToggled] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 1020) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    if (window.innerWidth < 1500) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  };

  // create an event listener
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  });

  return (
    <div>
      <Grid container spacing={isMobile ? 0 : 2}>
        <Grid item xs></Grid>
        <Grid item xs={isMobile ? 11 : 7}>
          <Header navigate={navigate} />

          <FeedbackBanner />
          <br />
          {location.pathname !== "/accessibility" && (
            <Search
              advancedToggled={advancedToggled}
              setAdvancedToggled={setAdvancedToggled}
              returnedDevices={returnedDevices}
              setReturnedDevices={setReturnedDevices}
              setReturnedManufacturers={setReturnedManufacturers}
              setSelectedManu={setSelectedManu}
              setSelectedDev={setSelectedDev}
              selectedType={selectedType}
              navigate={navigate}
            />
          )}

          <Routes>
            <Route path="/" element={<Homepage isSmall={isSmall} />}></Route>
            <Route path="*" element={<Homepage to="/" replace />} />
            <Route path="/loading" element={<Loading />}></Route>
            <Route
              path="/accessibility"
              element={<Accessibility navigate={navigate} />}
            ></Route>
            <Route
              exact
              path="/device-search/:searchTerm"
              element={
                <DeviceSearch
                  returnedDevices={returnedDevices}
                  setReturnedDevices={setReturnedDevices}
                  setSelectedDev={setSelectedDev}
                  setSelectedType={setSelectedType}
                  navigate={navigate}
                  isMobile={isMobile}
                />
              }
            />
            <Route
              exact
              path="/device-details/:gmdnTermName"
              element={
                <DeviceDetails
                  selectedDev={selectedDev}
                  setSelectedDev={setSelectedDev}
                  setSelectedType={setSelectedType}
                  setSelectedManu={setSelectedManu}
                  navigate={navigate}
                  isMobile={isMobile}
                />
              }
            />
            <Route
              exact
              path="/manufacturer-search/:searchTerm"
              element={
                <ManufacturerSearch
                  returnedManufacturers={returnedManufacturers}
                  setReturnedManufacturers={setReturnedManufacturers}
                  setSelectedManu={setSelectedManu}
                  setSelectedType={setSelectedType}
                  navigate={navigate}
                  isMobile={isMobile}
                />
              }
            />
            <Route
              exact
              path="/manufacturer-details/:id"
              element={
                <ManufacturerDetails
                  selectedManu={selectedManu}
                  setSelectedManu={setSelectedManu}
                  setSelectedType={setSelectedType}
                  setSelectedDev={setSelectedDev}
                  navigate={navigate}
                  isMobile={isMobile}
                />
              }
            />
          </Routes>
          {location.pathname !== "/accessibility" && <Faq />}

          <TwoLinks />
          <Footer />
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </div>
  );
}

export default withAITracking(reactPlugin, App);
