import React from "react";
const FaqContent = [
  {
    question: "What Device Identifiers can I search by?",
    answer: [
      "Medical Device Type - Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "GMDN® Code – the 5 digit Code",
      <></>,
      "Medical Device Risk Classification – these can be searched by:",
      <></>,
      <ul key="device-class">
        <li>{"Class I, IIa, IIb, Class III"}</li>
        <li>{"System or Procedure Pack "}</li>
        <li>{"Custom-Made devices (including class, where applicable)"}</li>
      </ul>,
      <p key="ivdd-uk-mdr">IVDD/UK MDR 2002:</p>,
      <ul key="ivdd-uk-mdr-list">
        <li>
          {
            "IVD Annex II List A, IVD Annex II List B, IVD General, IVD Devices for Self-Testing."
          }
        </li>
      </ul>,
      <p key="ivdr">IVDR:</p>,
      <ul key="ivdr-list">
        <li>
          {
            "Class A, Class B, Class B - Self-testing or Near-patient Testing, Class C, Class C - Self testing or Near patient Testing, Class C - Companion Diagnostics, Class D, Class D - Self-testing or Near-patient Testing, Class D - Companion Diagnostics."
          }
        </li>
      </ul>,
      <></>,
      "MHRA uses terminology to describe devices and classification per the International Medical Device Regulators Forum (IMDRF) Common Data Elements for Medical Device Identification, see:",
      <></>,
      <a
        href="https://www.imdrf.org/sites/default/files/docs/imdrf/final/technical/imdrf-tech-rps-common-data-elements.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Imdrf tech rps common data elements
      </a>,
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
    ],
  },
  {
    question: "What is GMDN®?",
    answer: [
      "”Global Medical Device Nomenclature” (GMDN®) is the globally recognised system for naming and classifying all medical devices (including implantables, medical equipment, consumables, and diagnostic devices), providing regulators, healthcare professionals, and manufacturers with a common language to communicate and share information by facilitating accurate identification, tracking, and reporting of medical devices. The GMDN Agency is a registered UK charity and non-profit organisation responsible for the ongoing maintenance of the GMDN database. The latest GMDN Agency annual report is available from the Charities Commission website. For more information, please visit:",
      <a
        href="https://www.gmdnagency.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.gmdnagency.org/
      </a>,
      <></>,
      "GMDN® is a registered trademark of The GMDN Agency. All rights reserved. The copyright and database rights in the original GMDN materials are owned by The GMDN Agency Ltd 2005-2023. Used under licence from The GMDN Agency Ltd.",
    ],
  },
  {
    question: "What Company information can I search for?",
    answer: [
      "UK and Non-UK Manufacturer Names and MHRA Reference Number – this is the organisation account number. If the account number has leading zeros, please do not include these when searching by MHRA Reference Number.",
    ],
  },
  {
    question: "How can I use Basic Search? ",
    answer: [
      "You can enter either Medical Device Type or manufacturer name in the Basic Search field.",
      <></>,
      "You must enter a minimum of 4 characters to enable search. If you wish to search with less than 4 characters please use Advanced Search.",
      <></>,
      "Registered devices are searched and displayed by Medical Device Type. This is the Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
      <></>,
      "If the text you have entered exists in both Medical Device Type and manufacturer name records, the device results will be displayed by default.",
      <></>,
      "To view the manufacturers that include the same text, click the MANUFACTURER option in the ‘Filter By’ section, to the left of the search results.",
      <></>,
      "To return to the device list click the DEVICES option in the ‘Filter By’ section, to the left of the search results.",
    ],
  },
  {
    question: "How do I see registered devices for a specific manufacturer?",
    answer: [
      "Registered devices are displayed alphabetically by Medical Device Type. This is the Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "MHRA uses terminology to describe devices and classification per the International Medical Device Regulators Forum (IMDRF) Common Data Elements for Medical Device Identification, see:",
      <></>,
      <a
        href="https://www.imdrf.org/sites/default/files/docs/imdrf/final/technical/imdrf-tech-rps-common-data-elements.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        Imdrf tech rps common data elements
      </a>,
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
      <></>,
      "Click on the manufacturer name (highlighted in dark blue) in the search result to view registered medical device types for the manufacturer.",
      <></>,
      "If you see the ‘DEVICES PENDING UPDATE BY MANUFACTURER’ message, please contact the manufacturer or access your account and take the necessary action. See:",
      <></>,
      <a
        href="https://www.gov.uk/guidance/register-medical-devices-to-place-on-the-market#making-changes-to-your-registration"
        target="_blank"
        rel="noopener noreferrer"
      >
        Making Changes to your registration
      </a>,
    ],
  },
  {
    question: "How can I use Advanced Search?",
    answer: [
      "This option enables you to input multiple key terms and combines them to perform a more specific search request. For example, it allows you to search for devices registered by specific manufacturers. Please note that using advanced search may result in longer loading times. If the account number has leading zeros, please do not include these when searching by MHRA Reference Number.",
      <></>,
      "Registered devices are searched and displayed by Medical Device Type. This is the Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
    ],
  },
  {
    question: "How do I use Filters?",
    answer: [
      "Search results on Basic Search will be defaulted to Device data.",
      <></>,
      "Search result on Advanced Search will be defaulted to which ever field you have search on. If multiple fields have been searched, e.g. manufacturer name and device, the default will be Manufacturer data.",
      <></>,
      "You can toggle between the views by clicking on DEVICE or MANUFACTURER in the ‘Filter By’ section, to the left of the search results.",
      <></>,
      "Please note that device filter displays all devices registered by all manufacturers in the search results, however, not all manufacturers will have registered all devices.",
      <></>,
      "To see which devices a specific manufacturer has registered, click on the manufacturer name (highlighted in dark blue) in the search to view registered devices for the manufacturer.",
      <></>,
      "The Sort  ALPHABETICALLY and by DATE REGISTERED are only applicable to the Manufacturer view.",
    ],
  },
  {
    question: "I can’t find the data I am looking for?",
    answer: [
      "PARD is updated weekly, usually at 11:30am on Monday. The update will include registrations completed before 8pm on Sunday. Please check again the following week to see registrations, renewals or updates completed after 8pm on Sunday.",
      <></>,
      "Try using fewer or more keywords, don’t include commas or full stops or Limited or Ltd. If the account number has leading zeros, please do not include these when searching by MHRA Reference Number.",
      <></>,
      "Registered devices are searched and displayed by Medical Device Type. This is the Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
    ],
  },
  {
    question: "Why am I getting no search results?",
    answer: [
      "PARD is updated weekly, usually at 11:30am on Monday. The update will include registrations completed before 8pm on Sunday. Please check again the following week to see registrations, renewals or updates completed after 8pm on Sunday.",
      <></>,
      "Registered devices are searched and displayed by Medical Device Type. This is the Global Medical Device Nomenclature (GMDN®) Term, which describes the medical device type e.g. catheter, wheelchair etc.",
      <></>,
      "Brand/Trade/Proprietary names of registered devices are not currently displayed on PARD.",
      <></>,
      "Please note that registrations for in vitro diagnostic medical devices for Performance Evaluation or Studies are not displayed on PARD. This is because these devices are not placed on the market.",
      <></>,
      "Suspended accounts are not displayed on PARD. If a registration is not displaying on PARD, please contact the manufacturer or access your account and take any necessary action to bring your registration up to date. See:",
      <></>,
      <a
        href="https://www.gov.uk/guidance/register-medical-devices-to-place-on-the-market#review-registration"
        target="_blank"
        rel="noopener noreferrer"
      >
        Review registration
      </a>,
      <></>,
      <a
        href="https://www.gov.uk/guidance/register-medical-devices-to-place-on-the-market#making-changes-to-your-registration"
        target="_blank"
        rel="noopener noreferrer"
      >
        Making Changes to your registration
      </a>,
      <></>,
      "If you still you cannot find an entry that you are expecting to see, or data appear to be incorrect, please email device.registrations@mhra.gov.uk with full details, your organisation name and account number.",
    ],
  },
  {
    question: "Why is my search taking so long?",
    answer: [
      "The registration database contains a large volume of data; please wait until the ‘Loading results’ message has cleared.",
    ],
  },
  {
    question: "What data can I download?",
    answer: [
      "The Download CSV function enables you to download data for which you search, in the format of Comma Separated Values (CSV). The download will reflect the search result you are seeing on the screen as follows:",
      <></>,
      <b>
        {
          "Search by Manufacturer – where multiple manufacturer records are returned in search result"
        }
      </b>,
      <></>,
      "List of Manufacturer names, reference numbers, addresses and UKRP/ Northern Ireland Authorised Representatives names and address details, if applicable. No device data will be included.",
      <></>,
      <b>{"Download single Manufacturer record from the search results"}</b>,
      <></>,
      "On selection of a single Manufacturer record from the search results, all data related to this specific Manufacturer will be downloaded. This includes Manufacturer name, reference number, address, UKRP/ Northern Ireland Authorised Representative name and address, if applicable, and device data including GMDN®  Code and Term and device classification.",
      <></>,
      <b>{"Search by Devices"}</b>,
      <></>,
      "List of all devices by search criteria entered including GMDN® Code and Term and device classification. No manufacturer or UKRP/ Northern Ireland Authorised Representative data will be included.",
      <></>,
      <b>{"Download single Device record from the search results"}</b>,
      <></>,
      "Single device details including GMDN® Code and Term and Class/Type. List of all the Manufacturers who have registered this specific Device, including Manufacturer names, reference numbers, addresses and UKRP/ Northern Ireland Authorised Representative names and addresses, if applicable. ",
      <></>,
      <b>{"Please note: "}</b>,
      <></>,
      "It is not currently possible to download a CSV that includes all/multiple devices and all/multiple manufacturers. If you wish to save any formatting changes you make to the CSV download, you will need to save as e.g. Excel Worksheet.",
    ],
  },
  {
    question: "What is Date Registered?",
    answer: ["This is the date when MHRA registered the manufacturer."],
  },
  {
    question: "Why am I seeing the previous search?",
    answer: [
      "The previous search results will remain visible on the screen until a new search has loaded.",
    ],
  },
  {
    question: "Where can I provide feedback?",
    answer: [
      "Please click on the link at the top of the screen to generate an email for your feedback, or email PARD.Feedback@mhra.gov.uk.  All suggestions for improvements and enhancements will be compiled for possible future development. ",
      <></>,
      "We are particularly interested in your views on:",
      <></>,
      <ul key="feedback-class">
        <li>{"How easy was it to find what you were looking for?"}</li>
        <li>{"Did you experience any technical issues?"}</li>
        <li>{"How useful do you find the download to CSV function?"}</li>
        <li>{"Did you find the FAQs helpful?"}</li>
        <li>{"Do you have any suggestions for new FAQs?"}</li>
      </ul>,
      <></>,
      "If you have any issues related to specific data e.g. you cannot find an entry that you are expecting to see or data appear to be incorrect, please email device.registrations@mhra.gov.uk with full details, including your organisation name and account number.",
    ],
  },
  {
    question: "Who needs to register with MHRA?",
    answer: [
      "All medical devices must be registered with MHRA before being placed on the Great Britain (England, Scotland, Wales) market. Different requirements apply to Northern Ireland. Please see full details on our website at: ",
      <></>,
      <a
        href="https://www.gov.uk/guidance/regulating-medical-devices-in-the-uk"
        target="_blank"
        rel="noopener noreferrer"
      >
        Regulating medical devices in the UK
      </a>,
      <></>,
      <a
        href="https://www.gov.uk/guidance/register-medical-devices-to-place-on-the-market"
        target="_blank"
        rel="noopener noreferrer"
      >
        Register medical devices to place on the market
      </a>,
      <></>,
    ],
  },
  {
    question:
      "Where can I find Manufacturer/UKRP/NI Authorised Representative contact information?",
    answer: [
      "Manufacturer/UKRP/NI Authorised Representative Customer Service contact telephone number and/or email address display, where provided by organisation. If not available, please check the organisation’s website.",
    ],
  },
];

export default FaqContent;
