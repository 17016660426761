export default function sortByAlphabetical(data, fieldName) {
  let sortedArray = data;
  sortedArray.sort(function (a, b) {
    var nameA = a[fieldName].toUpperCase();
    var nameB = b[fieldName].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
}
