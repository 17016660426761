import React from "react";
import { Select, FormControl, InputLabel } from "@mui/material";
import TextWithTooltip from "./shared/TextWithTooltip";
import "../styles/styles.css";

export default function SelectSearchfield({
  hint,
  text,
  id,
  dataCy,
  value,
  onChangeEvent,
  options,
}) {
  const defaultLabel = value ? "" : text.slice(0, -1);
  return (
    <>
      <TextWithTooltip hint={hint} text={text} />
      <FormControl className="form-control">
        <InputLabel>{defaultLabel}</InputLabel>
        <Select
          id={id}
          label={defaultLabel}
          data-cy={dataCy}
          value={value}
          native={true}
          onChange={(event) => {
            onChangeEvent(event.target.value);
          }}
          className="select"
        >
          <option value=""></option>
          {options.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
