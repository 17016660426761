import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import "../styles/styles.css";

function FaqResult(props) {
  const [showMoreClicked, setShowMoreClicked] = useState([]);
  const uniqueIdShowAnswer = `show-more-button-${props.question.replace(
    /\s+/g,
    "-"
  )}`;
  const uniqueIdHideAnswer = `hide-answer-button-${props.question.replace(
    /\s+/g,
    "-"
  )}`;

  function handleShowMoreClicked() {
    if (showMoreClicked) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }

  return (
    <div className="faq-result-container">
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography
            role="dialog"
            variant="p"
            component="p"
            className="text-align-left-bold"
            aria-label={props.question}
          >
            {props.question}
          </Typography>
        </Grid>
      </Grid>
      {showMoreClicked ? (
        <Typography
          variant="p"
          component="p"
          className="text-align-right"
          onClick={handleShowMoreClicked}
        >
          <Button id={uniqueIdShowAnswer} data-cy="showAnswerButton">
            Show Answer
          </Button>
        </Typography>
      ) : (
        <div>
          <br />
          {props.answer.map((text, index) => {
            return (
              <div key={index}>
                <Typography
                  role="dialog"
                  variant="p"
                  component="span"
                  className="faq-answer"
                  aria-label={props.answer}
                >
                  {text}
                </Typography>
                <br />
              </div>
            );
          })}

          <Typography
            variant="p"
            component="p"
            className="text-align-right"
            onClick={handleShowMoreClicked}
          >
            <Button id={uniqueIdHideAnswer} data-cy="hideAnswerButton">
              Hide Answer
            </Button>
          </Typography>
        </div>
      )}
    </div>
  );
}

export default FaqResult;
