import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ManufacturerResult from "./ManufacturerResult";
import { CSVLink } from "react-csv";
import SearchDisclaimer from "./SearchDisclaimer";
import ManufacturerSearchHeaders from "./ManufacturerSearchHeaders";
import sortByAlphabetical from "./shared/SortByAlphabetical";
import sortByDate from "./shared/SortByDate";
import scrollToTop from "./shared/ScrollToTop";
import "../styles/styles.css";
import Loading from "./Loading";
import { useParams } from "react-router-dom";
import searchDatabase from "./shared/SearchDatabase";
import moment from "moment";
import Back from "./Back";
import Pagination from "./shared/Paginate";
import handlePageChange from "./shared/HandlePageChange";

function ManufacturerSearch(props) {
  const [recievedData, setRecievedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 20;
  const [startIndex, setStartIndex] = useState(currentPage * pageSize);
  const [endIndex, setEndIndex] = useState(startIndex + pageSize);
  const [databaseSearched, setDatabaseSearched] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const pageNumbers = [];

  let { searchTerm } = useParams();

  const [filterSelected, setFilterSelected] = useState("Alphabetically");

  if (props.testing) {
    searchTerm = "test";
  }
  useEffect(() => {
    scrollToTop();
    if (props.returnedManufacturers.length > 0) {
      setRecievedData(props.returnedManufacturers);
      setDatabaseSearched(true);
      setPageCount(Math.ceil(props.returnedManufacturers.length / pageSize));
      setCurrentData(recievedData.slice(startIndex, endIndex));
    } else {
      searchDatabase(
        searchTerm,
        setRecievedData,
        props.setReturnedManufacturers,
        "/searchManufacturersAdvanced",
        "/searchManufacturers",
        setDatabaseSearched
      );
      setPageCount(1);
    }
    //eslint-disable-next-line
  }, [databaseSearched]);

  function handlePageClicked(newPage) {
    handlePageChange(
      newPage,
      setCurrentPage,
      currentPage,
      startIndex,
      setStartIndex,
      setEndIndex,
      setCurrentData,
      setPageCount,
      pageSize,
      recievedData
    );
  }

  function handleAlphabeticalFilter() {
    sortByAlphabetical(recievedData, "MAN_ORGANISATION_NAME");
    handlePageClicked(0);
    setFilterSelected("Alphabetically");
  }

  function handleDateFilter() {
    sortByDate(recievedData, "MAN_CREATED_DATE");
    handlePageClicked(0);
    setFilterSelected("Date");
  }

  function handleDevicesClicked() {
    props.setSelectedType("devices");
    props.navigate(`/device-search/${encodeURIComponent(searchTerm)}`);
  }

  if (databaseSearched || props.testing) {
    return (
      <div>
        <Back navigate={props.navigate} goToHome={true} />
        <SearchDisclaimer />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="p" component="p">
              Filter by
            </Typography>
            <br />
            <Typography variant="p" component="p">
              Type
            </Typography>
            <Button
              onClick={handleDevicesClicked}
              id="device-filter-button"
              data-cy="deviceFilterButton"
              data-testid="deviceFilterButton"
            >
              Devices
            </Button>
            <br />
            <Button
              disabled={true}
              id="manufacturer-filter-button"
              data-cy="manufacturerFilterButton"
            >
              Manufacturers
            </Button>
            <br />
            <Typography variant="p" component="p">
              Sort
            </Typography>
            <>
              <Button
                disabled={filterSelected === "Alphabetically"}
                onClick={handleAlphabeticalFilter}
                id="alphabetical-filter-button"
                data-cy="alphabeticalFilterButton"
                data-testid="alphabeticalFilterButton"
              >
                Alphabetically
              </Button>
              <br />
              <Button
                disabled={filterSelected !== "Alphabetically"}
                className="text-align-left"
                onClick={handleDateFilter}
                id="date-filter-button"
                data-cy="dateFilterButton"
                data-testid="dateFilterButton"
              >
                Date Registered
              </Button>
            </>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography
                  variant="p"
                  component="p"
                  className={"text-align-left"}
                >
                  Results ({recievedData.length})
                </Typography>
                <br />
              </Grid>
              <Grid item xs>
                <Typography
                  variant="p"
                  component="p"
                  className={"text-align-right"}
                >
                  Page {currentPage + 1} of {pageCount}
                  <br />
                  <br />
                  <CSVLink
                    headers={ManufacturerSearchHeaders}
                    data={recievedData.map((row) => ({
                      ...row,
                      MAN_CREATED_DATE: moment(row.MAN_CREATED_DATE).format(
                        "YYYY-MM-DD"
                      ),
                    }))}
                    filename="manufacturer-data.csv"
                    data-cy="downloadCsvLink"
                  >
                    Download CSV
                  </CSVLink>
                </Typography>
              </Grid>
            </Grid>
            {props.isMobile ? (
              <></>
            ) : (
              <div>
                {currentData.map((manufacturer) => (
                  <ManufacturerResult
                    key={manufacturer.MAN_ORGANISATION_ID}
                    manufacturer={manufacturer}
                    setSelectedManu={props.setSelectedManu}
                    navigate={props.navigate}
                    pageNumbers={pageNumbers}
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  onPageChange={handlePageClicked}
                  pageSize={pageSize}
                  totalItems={recievedData.length}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {props.isMobile ? (
          <div>
            {currentData.map((manufacturer) => (
              <ManufacturerResult
                key={manufacturer.MAN_ORGANISATION_ID}
                manufacturer={manufacturer}
                setSelectedManu={props.setSelectedManu}
                navigate={props.navigate}
                pageNumbers={pageNumbers}
              />
            ))}

            <Pagination
              currentPage={currentPage}
              onPageChange={handlePageClicked}
              pageSize={pageSize}
              totalItems={recievedData.length}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default ManufacturerSearch;
