import scrollToTop from "./ScrollToTop";

export default function handlePageChange(
  newPage,
  setCurrentPage,
  currentPage,
  startIndex,
  setStartIndex,
  setEndIndex,
  setCurrentData,
  setPageCount,
  pageSize,
  recievedData
) {
  setCurrentPage(newPage);
  setStartIndex(currentPage * pageSize);
  setEndIndex(startIndex + pageSize);
  setCurrentData(
    recievedData.slice(newPage * pageSize, newPage * pageSize + pageSize)
  );
  setPageCount(Math.ceil(recievedData.length / pageSize));
  scrollToTop();
}
