import { Button, Typography } from "@mui/material";
import FaqResult from "./FaqResult";
import FaqContent from "./FaqContent";
import "../styles/styles.css";
import React, { useState } from "react";

function Faq() {
  const [showMoreClicked, setShowMoreClicked] = useState([]);

  function handleShowMoreClicked() {
    if (showMoreClicked) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }

  const hideButtonId = `hide-faqs-button-${Date.now()}`;

  return (
    <div data-testid="faqId" className="result-container">
      <Typography variant="p" component="p" className="text-align-left-bold">
        Frequently Asked Questions (FAQ)
      </Typography>
      {showMoreClicked ? (
        <Typography
          variant="p"
          component="p"
          className="text-align-faq-right"
          onClick={handleShowMoreClicked}
        >
          <Button id="show-more-button" data-cy="showAnswerButton">
            SHOW FAQs
          </Button>
        </Typography>
      ) : (
        <div>
          <Typography
            variant="p"
            component="p"
            className="text-align-faq-right"
            onClick={handleShowMoreClicked}
          >
            <Button data-cy="hideFaqsButton">HIDE FAQs</Button>
          </Typography>
          <br />
          {FaqContent.map((result) => {
            return (
              <FaqResult
                key={result.question}
                question={result.question}
                answer={result.answer}
              />
            );
          })}
          <Typography
            variant="p"
            component="p"
            className="text-align-faq-right"
            onClick={handleShowMoreClicked}
          >
            <Button id={hideButtonId} data-cy="hideFaqsButton">
              HIDE FAQs
            </Button>
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Faq;
