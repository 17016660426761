import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import SearchField from "./SearchField";

function SimpleSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    props.handleSimpleSubmit(searchTerm);
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SearchField
          hint="The Global Medical Device Nomenclature (GMDN®) Term which describes the medical device type e.g. catheter, wheelchair etc. You do not need to know the full term, see FAQ for search help."
          text="Enter Medical Device Type or Manufacturer Name:"
          id="search-input"
          dataCy="simpleSearchInput"
          onChangeEvent={setSearchTerm}
          value={searchTerm}
          helperText={
            searchTerm.length > 255
              ? "You have exceeded the maximum of 255 characters"
              : ""
          }
          error={searchTerm.length > 255}
        />

        <Tooltip
          title={
            searchTerm.length < 4 ? "Please enter at least 4 characters" : ""
          }
        >
          <span role="note" aria-label="Please enter at least 4 characters">
            <Button
              variant="contained"
              type="submit"
              id="submit-button"
              data-cy="simpleSearchSubmit"
              disabled={searchTerm.length > 255 || searchTerm.length < 4}
            >
              Search
            </Button>
          </span>
        </Tooltip>
      </form>
    </div>
  );
}
export default SimpleSearch;
