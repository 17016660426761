import React from "react";

function TwoLinks() {
  return (
    <div data-testid="twoLinksCopyID" className="footer-elements">
      <a
        href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Freport-problem-medicine-medical-device&data=05%7C01%7CSukh.Virdi2%40mhra.gov.uk%7C2bc046b685014190049c08dae4ddfa6c%7Ce527ea5c62584cd2a27f8bd237ec4c26%7C0%7C0%7C638073939301956855%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=tHg7mLvSBW8lS00RSFeqJaB7ODdBv9qaBhqymQaZ5gw%3D&reserved=0"
        data-testid="reportAProblem"
        target="_blank"
        rel="noreferrer"
      >
        Report a problem with a medicine or medical device
      </a>
      <a
        href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.gov.uk%2Fdrug-device-alerts&data=05%7C01%7CSukh.Virdi2%40mhra.gov.uk%7C2bc046b685014190049c08dae4ddfa6c%7Ce527ea5c62584cd2a27f8bd237ec4c26%7C0%7C0%7C638073939301956855%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=df9fEveL5%2B0sZYCcmuXKVvO%2FXatm6x4QdifKDzu5x2U%3D&reserved=0"
        data-testid="alertsRecallsSafety"
        target="_blank"
        rel="noreferrer"
      >
        Alerts, recalls and safety information: drugs and medical devices
      </a>
    </div>
  );
}

export default TwoLinks;
