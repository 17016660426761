import React, { useState } from "react";
import { Button, Tooltip } from "@mui/material";
import SearchField from "./SearchField";
import SelectSearchfield from "./SelectSearchfield";
import DeviceClasses from "./DeviceClasses";

function AdvancedSearch(props) {
  const [manufacturerName, setManufacturerName] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [gmdnCode, setGmdnCode] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    props.handleAdvancedSubmit(
      manufacturerName,
      referenceNumber,
      deviceName,
      deviceType,
      gmdnCode
    );
  }

  function handleClearPressed() {
    setManufacturerName("");
    setReferenceNumber("");
    setDeviceName("");
    setDeviceType("");
    setGmdnCode("");
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SearchField
          hint="The Manufacturer Name"
          text="Manufacturer Name:"
          id="manufacturer-name"
          dataCy="advancedSearchManName"
          advancedSearch={true}
          onChangeEvent={setManufacturerName}
          value={manufacturerName}
          helperText={
            manufacturerName.length > 200
              ? "You have exceeded the maximum of 200 characters"
              : ""
          }
          error={manufacturerName.length > 200}
        />
        <SearchField
          hint="The Organisation Account Number. Please do not include leading zeros"
          text="MHRA Reference Number:"
          id="reference-number"
          dataCy="advancedSearchRefNumber"
          advancedSearch={true}
          onChangeEvent={setReferenceNumber}
          value={referenceNumber}
          helperText={
            referenceNumber.length > 10
              ? "You have exceeded the maximum of 10 characters"
              : ""
          }
          error={referenceNumber.length > 10}
        />
        <SearchField
          hint="The Global Medical Device Nomenclature (GMDN®) Code"
          text="GMDN® Code:"
          id="gdmn-code"
          dataCy="advancedSearchGmdnCode"
          advancedSearch={true}
          onChangeEvent={setGmdnCode}
          value={gmdnCode}
          helperText={
            gmdnCode.length > 5
              ? "You have exceeded the maximum of 5 characters"
              : ""
          }
          error={gmdnCode.length > 5}
        />
        <SearchField
          hint="The Global Medical Device Nomenclature (GMDN®) Term which describes the medical device type e.g. catheter, wheelchair etc"
          text="Medical Device Type:"
          id="device-name"
          dataCy="advancedSearchDeviceName"
          advancedSearch={true}
          onChangeEvent={setDeviceName}
          value={deviceName}
          helperText={
            deviceName.length > 255
              ? "You have exceeded the maximum of 255 characters"
              : ""
          }
          error={deviceName.length > 255}
        />{" "}
        <label htmlFor="dataType">
          <SelectSearchfield
            hint="Class of device e.g. III, Custom-made, System or Procedure Pack"
            text="Medical Device Risk Classification:"
            role="combobox"
            id={deviceType}
            testId="deviceSelect"
            dataCy="advancedSearchDeviceClass"
            onChangeEvent={setDeviceType}
            value={deviceType}
            options={DeviceClasses}
          />
        </label>
        <Tooltip
          title={
            manufacturerName.length === 0 &&
            referenceNumber.length === 0 &&
            deviceName.length === 0 &&
            deviceType.length === 0 &&
            gmdnCode.length === 0
              ? "Please enter at least one search term"
              : ""
          }
        >
          <span role="note" aria-label="Please enter at least one search term">
            <Button
              variant="contained"
              type="submit"
              id="search-submit-button"
              data-cy="advancedSearchSubmit"
              disabled={
                manufacturerName.length > 200 ||
                referenceNumber.length > 10 ||
                deviceName.length > 255 ||
                gmdnCode.length > 5 ||
                (manufacturerName.length === 0 &&
                  referenceNumber.length === 0 &&
                  deviceName.length === 0 &&
                  deviceType.length === 0 &&
                  gmdnCode.length === 0)
              }
            >
              Search
            </Button>
          </span>
        </Tooltip>
      </form>
      <span>
        <Button
          variant="contained"
          id="clear-button"
          data-cy="advancedClearButton"
          data-testid="advancedClearButton"
          onClick={handleClearPressed}
        >
          Clear
        </Button>
      </span>
    </div>
  );
}
export default AdvancedSearch;
