import React from "react";
import { Typography } from "@mui/material";

const AddressLine = ({ address }) => (
  <Typography variant="p" component="p" data-testid="id_ad">
    {address}
  </Typography>
);

export default function Address({ addressDetails }) {
  let count = 0;
  return (
    <>
      {addressDetails.map((addressLine) => {
        count++;
        return (
          <AddressLine
            key={addressLine + count.toString()}
            address={addressLine}
          />
        );
      })}
    </>
  );
}
