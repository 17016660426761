const ManufacturerSearchHeaders = [
  {
    label: "MHRA Reference Number",
    key: "MAN_ACCOUNT_NUMBER",
  },
  {
    label: "Date Manufacturer Registered",
    key: "MAN_CREATED_DATE",
  },
  {
    label: "Manufacturer Name",
    key: "MAN_ORGANISATION_NAME",
  },
  {
    label: "Manufacturer Address 1",
    key: "MAN_ADDR_LINE_1",
  },
  {
    label: "Manufacturer Address 2",
    key: "MAN_ADDR_LINE_2",
  },
  {
    label: "Manufacturer Address 3",
    key: "MAN_ADDR_LINE_3",
  },
  {
    label: "Manufacturer Address 4",
    key: "MAN_ADDR_LINE_4",
  },
  {
    label: "Manufacturer City",
    key: "MAN_CITY",
  },
  {
    label: "Manufacturer County State Province",
    key: "MAN_COUNTYSTATEPROVINCE",
  },
  {
    label: "Manufacturer Country",
    key: "MAN_COUNTRY",
  },
  {
    label: "Manufacturer Postcode",
    key: "MAN_POSTCODE",
  },
  {
    label: "Manufacturer Customer Service Email",
    key: "CUSTOMER_SERVICE_EMAIL_ADDRESS",
  },
  {
    label: "Manufacturer Customer Service Telephone",
    key: "CUSTOMER_SERVICE_TELEPHONE_NUMBER",
  },
  {
    label: "UKRP/Authorised Representative Name",
    key: "REP_NAME",
  },
  {
    label: "UKRP/Authorised Representative Address 1",
    key: "REP_ADDRESS_LINE_1",
  },
  {
    label: "UKRP/Authorised Representative Address 2",
    key: "REP_ADDRESS_LINE_2",
  },
  {
    label: "UKRP/Authorised Representative Address 3",
    key: "REP_ADDRESS_LINE_3",
  },
  {
    label: "UKRP/Authorised Representative Address 4",
    key: "REP_ADDRESS_LINE_4",
  },
  { label: "UKRP/Authorised Representative City", key: "REP_CITY" },
  {
    label: "UKRP/Authorised Representative County",
    key: "REP_COUNTY_STATE_PROVINCE",
  },
  { label: "UKRP/Authorised Representative Country", key: "REP_COUNTRY" },
  { label: "Organisation Role", key: "RELATIONSHIP" },
  { label: "UKRP/Authorised Representative Postcode", key: "REP_POSTCODE" },
  {
    label: "UKRP/Authorised Representative Customer Service Email",
    key: "REP_CUSTOMER_SERVICE_EMAIL_ADDRESS",
  },
  {
    label: "UKRP/Authorised Representative Customer Service Telephone",
    key: "REP_CUSTOMER_SERVICE_TELEPHONE_NUMBER",
  },
];

export default ManufacturerSearchHeaders;
