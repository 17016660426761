import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import scrollToTop from "./shared/ScrollToTop";
import Loading from "./Loading";
import searchDatabase from "./shared/SearchDatabase";
import Back from "./Back";
import DeviceSearchHeaders from "./DeviceSearchHeaders";
import DeviceResult from "./DeviceResult";
import SearchDisclaimer from "./SearchDisclaimer";
import "../styles/styles.css";
import Pagination from "./shared/Paginate";
import handlePageChange from "./shared/HandlePageChange";

function DeviceSearch(props) {
  const [recievedData, setRecievedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [databaseSearched, setDatabaseSearched] = useState(false);
  const pageSize = 20;
  const [startIndex, setStartIndex] = useState(currentPage * pageSize);
  const [endIndex, setEndIndex] = useState(startIndex + pageSize);
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const pageNumbers = [];

  let { searchTerm } = useParams();

  if (props.testing) {
    searchTerm = "test";
  }

  useEffect(() => {
    scrollToTop();
    if (props.returnedDevices.length > 0) {
      setRecievedData(props.returnedDevices);
      setDatabaseSearched(true);
      setPageCount(Math.ceil(props.returnedDevices.length / pageSize));
      setCurrentData(props.returnedDevices.slice(startIndex, endIndex));
    } else {
      searchDatabase(
        searchTerm,
        setRecievedData,
        props.setReturnedDevices,
        "/searchDevicesAdvanced",
        "/searchDevices",
        setDatabaseSearched
      );
      setPageCount(1);
    }
    //eslint-disable-next-line
  }, [databaseSearched]);

  function handlePageClicked(newPage) {
    handlePageChange(
      newPage,
      setCurrentPage,
      currentPage,
      startIndex,
      setStartIndex,
      setEndIndex,
      setCurrentData,
      setPageCount,
      pageSize,
      recievedData
    );
  }

  function handleManufacturersClicked() {
    props.setSelectedType("manufacturers");
    props.navigate(`/manufacturer-search/${encodeURIComponent(searchTerm)}`);
  }

  if (databaseSearched || props.testing) {
    return (
      <div data-testid="deviceSearchId">
        <Back navigate={props.navigate} goToHome={true} />
        <SearchDisclaimer />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography variant="p" component="p">
              Filter by
            </Typography>
            <br />
            <Typography variant="p" component="p">
              Type
            </Typography>
            <Button
              disabled={true}
              id="device-filter-button"
              data-cy="deviceFilterButton"
            >
              Devices
            </Button>
            <br />
            <Button
              onClick={handleManufacturersClicked}
              id="manufacturer-filter-button"
              data-cy="manufacturerFilterButton"
              data-testid="manufacturerFilterButton"
            >
              Manufacturers
            </Button>
            <br />
            <Typography variant="p" component="p">
              Sort
            </Typography>
            <Button
              disabled={true}
              id="alphabetical-filter-button"
              data-cy="alphabeticalFilterButton"
              data-testid="alphabeticalFilterButton"
            >
              Alphabetically
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Typography
                  variant="p"
                  component="p"
                  className="text-align-left"
                >
                  Results ({recievedData.length})
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="p"
                  component="p"
                  className="text-align-right"
                >
                  Page {currentPage + 1} of {pageCount}
                  <br />
                  <br />
                  <CSVLink
                    data={recievedData.map(
                      ({
                        MAN_ORGANISATION_ID,
                        DEVICE_ID,
                        DEVICE_STATUS_ID,
                        ...keepAttrs
                      }) => keepAttrs
                    )}
                    filename="device-data.csv"
                    headers={DeviceSearchHeaders}
                    id="download-csv-link"
                    data-cy="downloadCsvLink"
                  >
                    Download CSV
                  </CSVLink>
                </Typography>
              </Grid>
            </Grid>
            {props.isMobile ? (
              <></>
            ) : (
              <div>
                {currentData.map((device) => (
                  <DeviceResult
                    key={device.DEVICE_ID}
                    device={device}
                    setSelectedDev={props.setSelectedDev}
                    navigate={props.navigate}
                  />
                ))}
                <Pagination
                  currentPage={currentPage}
                  onPageChange={handlePageClicked}
                  pageSize={pageSize}
                  totalItems={recievedData.length}
                  pageNumbers={pageNumbers}
                />
              </div>
            )}
          </Grid>
        </Grid>
        {props.isMobile ? (
          <div>
            {currentData.map((device) => (
              <DeviceResult
                key={device.DEVICE_ID}
                device={device}
                setSelectedDev={props.setSelectedDev}
                navigate={props.navigate}
              />
            ))}

            <Pagination
              currentPage={currentPage}
              onPageChange={handlePageClicked}
              pageSize={pageSize}
              totalItems={recievedData.length}
              pageNumbers={pageNumbers}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default DeviceSearch;
