import React from "react";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import SimpleSearch from "./SimpleSearch";
import AdvancedSearch from "./AdvancedSearch";
import "../styles/styles.css";

function Search(props) {
  function clearSelected() {
    props.setSelectedManu("");
    props.setSelectedDev("");
  }

  function searchNavigate(
    manufacturerName = "",
    referenceNumber = "",
    searchTerm,
    devices
  ) {
    if (devices.length === 0) {
      props.navigate(`/manufacturer-search/${encodeURIComponent(searchTerm)}`);
    } else if (manufacturerName || referenceNumber) {
      props.navigate(`/manufacturer-search/${encodeURIComponent(searchTerm)}`);
    } else {
      props.navigate(`/device-search/${encodeURIComponent(searchTerm)}`);
    }
  }

  //Simple search
  function searchDatabase(searchTerm) {
    props.navigate("/loading");
    async function apifunc() {
      const data = {
        searchTerm: searchTerm,
      };
      try {
        const postManu = await axios.post("/searchManufacturers", data);
        const postDev = await axios.post("/searchDevices", data);

        await props.setReturnedDevices(postDev.data);
        await props.setReturnedManufacturers(postManu.data);
        clearSelected();
        searchNavigate("", "", searchTerm, postDev.data);
      } catch (err) {
        console.log(err);
      }
    }
    apifunc();
  }

  //Advanced search
  function searchDatabaseAdvanced(
    manufacturerName,
    referenceNumber,
    deviceName,
    deviceType,
    gmdnCode
  ) {
    props.navigate("/loading");
    async function apifunc() {
      const data = {
        searchTerm: {
          manufacturerName: manufacturerName,
          referenceNumber: referenceNumber,
          deviceName: deviceName,
          deviceType: deviceType,
          gmdnCode: gmdnCode,
        },
      };
      try {
        const postManu = await axios.post("/searchManufacturersAdvanced", data);
        const postDev = await axios.post("/searchDevicesAdvanced", data);

        await props.setReturnedDevices(postDev.data);
        await props.setReturnedManufacturers(postManu.data);
        clearSelected();
        searchNavigate(
          manufacturerName,
          referenceNumber,
          `advSearch=&&${manufacturerName}&&${referenceNumber}&&${deviceName}&&${deviceType}&&${gmdnCode}`,
          postDev.data
        );
      } catch (err) {
        console.log(err);
      }
    }
    apifunc();
  }

  function advancedSearchToggle() {
    props.setAdvancedToggled(!props.advancedToggled);
  }

  return (
    <div data-testid="searchCopyId">
      <Typography variant="h5" component="h1">
        Public Access Registration Database (PARD)
      </Typography>
      <div className="result-container">
        {props.advancedToggled ? (
          <>
            <AdvancedSearch handleAdvancedSubmit={searchDatabaseAdvanced} />
            <br />
            <Typography variant="p" component="p">
              <Button
                onClick={advancedSearchToggle}
                id="advanced-search-button"
                data-cy="advancedSearchToggleButton"
              >
                Basic Search
              </Button>
            </Typography>
          </>
        ) : (
          <>
            <SimpleSearch handleSimpleSubmit={searchDatabase} />
            <Typography variant="p" component="p">
              <Button
                onClick={advancedSearchToggle}
                id="advanced-search-button"
                data-cy="advancedSearchToggleButton"
              >
                Advanced Search
              </Button>
            </Typography>
          </>
        )}
      </div>
    </div>
  );
}
export default Search;
