const DeviceClasses = [
  "Active implantable Medical device",
  "Class A",
  "Class B",
  "Class B – Self-testing or Near-patient Testing",
  "Class C",
  "Class C – Companion Diagnostics",
  "Class C – Self-testing or Near-patient Testing",
  "Class D",
  "Class D – Companion Diagnostics",
  "Class D – Self-testing or Near-patient Testing",
  "Class I",
  "Class IIa",
  "Class IIb",
  "Class III",
  "Custom-made",
  "Custom-made - Active implantable Medical device",
  "Custom-made - Class I",
  "Custom-made - Class IIa",
  "Custom-made - Class IIb",
  "Custom-made - Class III",
  "Custom-made - System or Procedure Pack",
  "IVD Annex II List A",
  "IVD Annex II List B",
  "IVD Devices for Self-Testing",
  "IVD General",
  "System or Procedure Pack"
];

export default DeviceClasses;
