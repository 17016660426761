import { Typography } from "@mui/material";
import React from "react";
import "../styles/styles.css";

function SearchDisclaimer() {
  return (
    <div>
      <br />
      <div data-testid="searchDisclaimerId" className="homepage-text-style">
        <Typography variant="p" component="p">
          Registration of medical devices with the MHRA (the UK Competent
          Authority) does not represent any form of accreditation,
          certification, approval or endorsement by the MHRA. Therefore, it is
          not permitted to make any claims to this effect, including the use of
          any MHRA logos in any marketing materials, on device packaging, in the
          instructions for use, on laboratory tickets/dockets, or in any other
          documentation.
        </Typography>
        <br />
        <Typography variant="p" component="p">
          Where device status is displayed as 'Conformity Assessment Certificate
          Expired', existing medical device stock already placed on the UK
          market (including stock in UK warehouses) prior to the expiry of the
          conformity assessment certificate can remain on the UK market.
          However, devices (including new stock) with expired conformity
          assessment certificates cannot be registered or placed on the UK
          market.
        </Typography>
      </div>
    </div>
  );
}

export default SearchDisclaimer;
