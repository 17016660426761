import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: "rgb(15, 18, 144)",
        },
        text: {
          color: "#0f1290",
        },
        resultText: {
          color: "#0f1290",
          paddingLeft: "0px",
          textAlign: "left",
          fontSize: "17px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          marginTop: 5,
          fontWeight: "bold",
        },
        h3: {
          marginTop: 15,
          fontSize: "1.5rem",
          fontWeight: "bold",
          marginBottom: 15,
        },
        h4: {
          marginTop: 15,
          fontSize: "1.3rem",
          fontWeight: "bold",
          marginBottom: 15,
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
