import React from "react";
import { Typography } from "@mui/material";
import "../styles/styles.css";
import SearchDisclaimer from "./SearchDisclaimer";

function Homepage() {
  return (
    <div>
      <br />
      <div data-testid="homepageCopyId" className="homepage-text-style">
        <Typography variant="p" component="p">
          The MHRA public access registration database (PARD) website allows you
          to find:
        </Typography>
        <ul>
          <li>
            <Typography variant="p" component="p">
              Registered manufacturers
            </Typography>
          </li>
          <li>
            <Typography variant="p" component="p">
              Registered medical device types
            </Typography>
          </li>
        </ul>
      </div>
      <SearchDisclaimer />
    </div>
  );
}

export default Homepage;
