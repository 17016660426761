import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "../styles/styles.css";

function DeviceResult(props) {
  const device = props.device;

  function handleDevSelected() {
    props.setSelectedDev(device);
    let encodedParam = encodeURIComponent(
      `${device.GMDN_TERM_NAME}&&${device.DEVICE_SUB_TYPE_DESC}`
    );
    props.navigate(`/device-details/${encodedParam}`);
  }
  return (
    <div className="result-container">
      <Grid container spacing={3}>
        <Grid item xs>
          <Grid container spacing={3}>
            <Grid item xs>
              {/* The GMDN Code */}
              <Typography
                variant="p"
                component="p"
                className="text-align-left-bold"
              >
                GMDN Code: {device.GMDN_CODE}
              </Typography>
            </Grid>
            <Grid item xs>
              {/* The Device Registration Status */}
              <Typography
                variant="p"
                component="p"
                className="text-align-right-bold"
              >
                {props.showStatus ? (
                  device.DEVICE_REG_STATUS_CODE === "DREGIY" ? (
                    <>Status: Registered</>
                  ) : (
                    <>Status: Conformity Assessment Certificate Expired</>
                  )
                ) : (
                  <> </>
                )}
              </Typography>
            </Grid>
          </Grid>
          {/* The Device Name */}
          <Typography variant="p" component="p">
            <Button
              variant="resultText"
              id={device.DEVICE_ID}
              data-cy="deviceName"
              onClick={handleDevSelected}
              data-testid="returnedGmdnTermName"
            >
              {device.GMDN_TERM_NAME}
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs>
          {/* The Device Class */}
          <Typography
            variant="p"
            component="p"
            className="text-align-left-bold"
          >
            Medical Device Risk Classification:
          </Typography>
          {device.DEVICE_SUB_TYPE_DESC ? (
            <Typography variant="p" component="p" className="text-align-left">
              {device.DEVICE_SUB_TYPE_DESC}
            </Typography>
          ) : (
            <Typography
              variant="p"
              component="p"
              className="text-align-left"
              data-testid="unknownClassName"
            >
              Unknown
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DeviceResult;
