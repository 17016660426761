import React from "react";
import { Button } from "@mui/material";
import "../styles/styles.css";

function Back(props) {
  function handleClick() {
    if (props.goToHome) {
      props.navigate("/");
    } else {
      props.navigate(-1);
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        className="back-button"
        id="back-button"
        data-cy="backButton"
        data-testid="backButton"
        onClick={handleClick}
      >
        Back
      </Button>
    </div>
  );
}

export default Back;
