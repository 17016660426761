import { Typography } from "@mui/material";
import React from "react";
import SearchDisclaimer from "./SearchDisclaimer";
import "../styles/styles.css";

function Loading() {
  return (
    <div data-testid="loadingId">
      <SearchDisclaimer />
      <br />
      <Typography variant="p" component="p" className="loading-text">
        Loading results...
      </Typography>
    </div>
  );
}

export default Loading;
