import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import "../styles/styles.css";
import Back from "./Back";
import scrollToTop from "./shared/ScrollToTop";

function Accessibility(props) {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div data-testid="accessibilityCopyId" className="homepage-text-style">
      <Back navigate={props.navigate} />
      <Typography variant="h3" component="h2">
        PARD Accessibility Statement
      </Typography>
      <Typography variant="p" component="p">
        This website is run by the Medicines and Healthcare products Regulatory
        Agency. We want as many people as possible to be able to use this
        website. For example, that means you should be able to:
      </Typography>
      <ul>
        <li>
          <Typography variant="p" component="p">
            change colours, contrast levels and fonts
          </Typography>
        </li>
        <li>
          <Typography variant="p" component="p">
            zoom in up to 300% without the text spilling off the screen
          </Typography>
        </li>
        <li>
          <Typography variant="p" component="p">
            navigate most of the website using just a keyboard
          </Typography>
        </li>
        <li>
          <Typography variant="p" component="p">
            listen to most of the website using a screen reader
          </Typography>
        </li>
      </ul>
      <Typography variant="p" component="p">
        We have also made the website text as simple as possible to understand.
      </Typography>
      <a
        href="https://mcmw.abilitynet.org.uk/"
        data-testid="accessibilityAbilityNet"
        target="_blank"
        rel="noreferrer"
      >
        AbilityNet
      </a>
      &nbsp;has advice on making your device easier to use if you have a
      disability.
      <Typography variant="h4" component="h3">
        How accessible this website is
      </Typography>
      <Typography variant="p" component="p">
        This website is fully compliant with accessibility standards.
      </Typography>
      <Typography variant="h4" component="h3">
        What to do if you cannot access parts of this website
      </Typography>
      <Typography variant="p" component="p">
        If you need information on this website in a different format like an
        accessible PDF, large print, easy read, audio recording or braille:
      </Typography>
      <ul>
        <li>
          email&nbsp;
          <a
            href="mailto:webupdates@mhra.gov.uk"
            data-testid="accessibilityContactUs"
            target="_top"
          >
            webupdates@mhra.gov.uk
          </a>
        </li>
      </ul>
      <Typography variant="p" component="p">
        We will consider your request and get back to you in 18 working days.
      </Typography>
      <Typography variant="h4" component="h3">
        Reporting accessibility problems with this website
      </Typography>
      <Typography variant="p" component="p">
        We are always looking to improve the accessibility of this website. If
        you find any problems not listed on this page or think we are not
        meeting accessibility requirements, contact:&nbsp;
        <a
          href="mailto:webupdates@mhra.gov.uk"
          data-testid="accessibilityContactUs2"
          target="_top"
        >
          webupdates@mhra.gov.uk
        </a>
        .
        <br />
        This email will go to our News, Digital and Content Team.
      </Typography>
      <Typography variant="h4" component="h3">
        Enforcement procedure
      </Typography>
      <Typography variant="p" component="p">
        The Equality and Human Rights Commission (EHRC) is responsible for
        enforcing the Public Sector Bodies (Websites and Mobile Applications)
        (No. 2) Accessibility Regulations 2018 (the ‘accessibility
        regulations’). If you’re not happy with how we respond to your
        complaint,&nbsp;
        <a
          href="https://www.equalityadvisoryservice.com/"
          data-testid="accessibilityEqualityAdvisoryService"
          target="_blank"
          rel="noreferrer"
        >
          contact the Equality Advisory and Support Service (EASS)
        </a>
        .
      </Typography>
      <Typography variant="h3" component="h2">
        Technical information about this website's accessibility
      </Typography>
      <Typography variant="p" component="p">
        The Medicines and Healthcare products Regulatory Agency is committed to
        making its website accessible, in accordance with the Public Sector
        Bodies (Websites and Mobile Applications) (No. 2) Accessibility
        Regulations 2018.
        <br />
        This website is fully compliant with the&nbsp;
        <a
          href="https://www.w3.org/TR/WCAG21/"
          data-testid="accessibilityW3"
          target="_blank"
          rel="noreferrer"
        >
          Web Content Accessibility Guidelines version 2.1
        </a>
        &nbsp;AA standard.
      </Typography>
      <Typography variant="h3" component="h2">
        How we have tested this website
      </Typography>
      <Typography variant="p" component="p">
        This website has been thoroughly tested during the final quarter of 2022
        and early 2023. The test has been carried out by the internal testing
        and development team at the Medicines and Healthcare products Regulatory
        Agency. We have used the following testing approach below. The functions
        of the website have been tested on:
      </Typography>
      <ul>
        <li>
          <Typography variant="p" component="p">
            Chrome
          </Typography>
        </li>
        <li>
          <Typography variant="p" component="p">
            Firefox
          </Typography>
        </li>
        <li>
          <Typography variant="p" component="p">
            Internet Explorer 11
          </Typography>
        </li>
      </ul>
      <Typography variant="p" component="p">
        We have tested different search terms on the website, using a range of
        different advanced and simple search combinations. We have used a colour
        picker tool and the WCAG site to check colour contrast. We have tested
        our website on both iOS (using Apple mobile devices) and Android (using
        Samsung mobile devices) to ensure that it works seamlessly across
        different platforms. In addition, within our CI/CD pipeline we have
        integrated automated tests by using the Cypress framework for end to end
        testing, Pa11y accessibility testing and an OWASP ZAP Baseline Scan to
        test the security level of our website.
        <br />
        This statement was prepared on 04 December 2022. It was last updated on
        08 March 2023.
      </Typography>
    </div>
  );
}

export default Accessibility;
