import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/styles.css";

function Footer() {
  let date = new Date();

  return (
    <div data-testid="footerId" className="footer-container">
      <a
        id="footer-logo"
        data-testid="footer-logo"
        href="https://www.gov.uk/government/organisations/medicines-and-healthcare-products-regulatory-agency"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="footer-logo"
          src={process.env.PUBLIC_URL + "/MHRA-logo.png"}
          alt="MHRA LOGO"
        />
      </a>
      <div className="footer-elements">
        <a
          id="foi-link"
          data-testid="footerFreedomOfInformation"
          href="https://www.gov.uk/government/publications/mhra-requests-under-the-freedom-of-information-act-foia"
          target="_blank"
          rel="noreferrer"
        >
          Freedom of Information
        </a>
        <a
          id="privacy-policy-link"
          data-testid="footerPrivacyPolicy"
          href="https://www.gov.uk/government/publications/mhra-privacy-notice/mhra-privacy-notice"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <Link data-testid="footerAccessibilityStatement" to="/accessibility">
          Accessibility Statement
        </Link>
        <a
          id="contact-us-link"
          data-testid="footerContactUs"
          href="mailto:device.registrations@mhra.gov.uk"
          target="_top"
        >
          Contact Us
        </a>
      </div>
      <br />
      <Typography>© {date.getFullYear()} Crown Copyright.</Typography>
    </div>
  );
}

export default Footer;
