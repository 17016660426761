import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import SearchDisclaimer from "./SearchDisclaimer";
import ManufacturerResult from "./ManufacturerResult";
import DeviceDetailsHeaders from "./DeviceDetailsHeaders";
import TextWithTooltip from "./shared/TextWithTooltip";
import "../styles/styles.css";
import { useParams } from "react-router-dom";
import scrollToTop from "./shared/ScrollToTop";
import Pagination from "./shared/Paginate";
import moment from "moment";
import Back from "./Back";
import handlePageChange from "./shared/HandlePageChange";

export default function DeviceDetails(props) {
  const [manufacturersByDevice, setManufacturersByDevice] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [gotData, setGotData] = useState(false);
  const pageSize = 20;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(pageSize);
  const [currentData, setCurrentData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const pageNumbers = [];

  let { gmdnTermName } = useParams();
  let splitParams = "";

  function getManufacturersByDevice() {
    scrollToTop();
    async function apifunc() {
      const data = {
        searchTerm: splitParams[0],
      };
      const manData = {
        deviceName: splitParams[0],
        deviceType: splitParams[1],
      };
      try {
        const post = await axios.post("/searchManufacturersByDevices", manData);
        if (!props.selectedDev) {
          const postDev = await axios.post("/searchDevices", data);
          props.setSelectedDev(postDev.data[0]);
        }
        setManufacturersByDevice(post.data);
        setCurrentData(post.data.slice(startIndex, endIndex));
        setPageCount(Math.ceil(post.data.length / pageSize));
        setGotData(true);
      } catch (err) {
        console.log(err);
      }
    }
    apifunc();
  }

  function handlePageClicked(newPage) {
    handlePageChange(
      newPage,
      setCurrentPage,
      currentPage,
      startIndex,
      setStartIndex,
      setEndIndex,
      setCurrentData,
      setPageCount,
      pageSize,
      manufacturersByDevice
    );
  }

  if (!gotData && !props.testing) {
    splitParams = gmdnTermName.split("&&");
    getManufacturersByDevice();
  } else {
    return (
      <>
        <Back navigate={props.navigate} />
        <SearchDisclaimer />
        <br />
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="p" component="h3">
              Full Device Summary
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="p" component="p" className="text-align-right">
              <CSVLink
                data-testid="deviceCsvLink"
                data-cy="downloadCsvLink"
                data={[props.selectedDev]
                  .concat(manufacturersByDevice)
                  .map((row) => ({
                    ...row,
                    MAN_CREATED_DATE: row.MAN_CREATED_DATE
                      ? moment(row.MAN_CREATED_DATE).format("YYYY-MM-DD")
                      : row.MAN_CREATED_DATE,
                  }))

                  .map(
                    ({ DEVICE_ID, DEVICE_STATUS_ID, ...keepAttrs }) => keepAttrs
                  )}
                headers={DeviceDetailsHeaders}
                filename={props.selectedDev.GMDN_TERM_NAME + ".csv"}
              >
                Download CSV
              </CSVLink>
            </Typography>
          </Grid>
        </Grid>
        <div className="result-container">
          <Grid container spacing={3}>
            <Grid item xs>
              {/* The GMDN Code */}
              <TextWithTooltip
                hint="The Global Medical Device Nomenclature (GMDN®) Code"
                text={"GMDN® Code: " + props.selectedDev.GMDN_CODE}
              />
            </Grid>
          </Grid>
          {/* The Device Name */}
          <TextWithTooltip
            hint="The GMDN® Term"
            text={props.selectedDev.GMDN_TERM_NAME}
            component="h3"
            className="text-with-tooltip-heading"
          />
          <div>
            <br />
            <Grid container spacing={3}>
              <Grid item xs>
                {/* The Device Class */}
                <TextWithTooltip
                  className="text-align-left-bold"
                  hint="Class of device e.g. III, Custom-made, System or Procedure Pack"
                  text={`Medical Device Risk Classification: ${
                    props.selectedDev.DEVICE_SUB_TYPE_DESC
                      ? props.selectedDev.DEVICE_SUB_TYPE_DESC
                      : "Unknown"
                  }`}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <br />
        <Grid container spacing={3}>
          <Grid item xs>
            <Typography variant="p" component="h3">
              Manufacturers ({manufacturersByDevice.length})
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="p"
              component="p"
              className={"text-align-right"}
            >
              Page {currentPage + 1} of {pageCount}
            </Typography>
          </Grid>
        </Grid>
        <div>
          {currentData.map((manufacturer) => {
            return (
              <ManufacturerResult
                key={manufacturer.MAN_ORGANISATION_ID}
                manufacturer={manufacturer}
                setSelectedManu={props.setSelectedManu}
                setSelectedType={props.setSelectedType}
                accessedFromDeviceDetails={true}
                navigate={props.navigate}
                isMobile={props.isMobile}
                pageNumbers={pageNumbers}
              />
            );
          })}
          <Pagination
            currentPage={currentPage}
            onPageChange={handlePageClicked}
            pageSize={pageSize}
            totalItems={manufacturersByDevice.length}
          />
        </div>
      </>
    );
  }
}
