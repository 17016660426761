import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Address from "./shared/Address";
import "../styles/styles.css";
import moment from "moment";

function ManufacturerResult(props) {
  const [showMoreClicked, setShowMoreClicked] = useState([]);
  const manufacturer = props.manufacturer;
  const uniqueId = `showMoreButton-${manufacturer.MAN_ORGANISATION_ID}`;

  let createdDate = new Date(props.manufacturer.MAN_CREATED_DATE);

  function handleShowMoreClicked() {
    if (showMoreClicked) {
      setShowMoreClicked(false);
    } else {
      setShowMoreClicked(true);
    }
  }

  function handleManuSelected() {
    props.setSelectedManu(manufacturer);
    props.navigate(`/manufacturer-details/${manufacturer.MAN_ORGANISATION_ID}`);
  }

  return (
    <div className="result-container" data-testid="manufacturerSearchId">
      <Grid container spacing={3}>
        <Grid item xs>
          {/* The MHRA Account Number */}
          <Typography
            variant="p"
            component="p"
            className="text-align-left-bold"
          >
            MHRA Reference Number: {manufacturer.MAN_ACCOUNT_NUMBER}
          </Typography>
        </Grid>
        <Grid item xs>
          {/* The date the manufacturer registered */}
          <Typography
            variant="p"
            component="p"
            className="text-align-right-bold"
          >
            Date Registered: {moment(createdDate).format("DD/MM/YYYY")}
          </Typography>
        </Grid>
      </Grid>
      {/* The name of the manufacturer */}
      <Typography variant="p" component="h3">
        <Button
          onClick={handleManuSelected}
          variant="resultText"
          id={manufacturer.MAN_ORGANISATION_ID}
          data-cy="returnedManufacturerName"
          data-testid="returnedManufacturerName"
        >
          {manufacturer.MAN_ORGANISATION_NAME}
        </Button>
      </Typography>

      {/* Determines if the show more button has been clicked */}
      {showMoreClicked ? (
        <Typography
          variant="p"
          component="p"
          className="text-align-right"
          onClick={handleShowMoreClicked}
        >
          <Button id={uniqueId} data-cy="showMoreButton">
            Show More
          </Button>
        </Typography>
      ) : (
        <div>
          <br />
          <Grid container spacing={3}>
            <Grid item xs>
              {/* Manufacturer Address */}
              <Typography
                variant="p"
                component="p"
                className="text-align-left-bold"
              >
                Manufacturer Address:
              </Typography>
              <Address
                addressDetails={[
                  manufacturer.MAN_ADDR_LINE_1,
                  manufacturer.MAN_ADDR_LINE_2,
                  manufacturer.MAN_ADDR_LINE_3,
                  manufacturer.MAN_ADDR_LINE_4,
                  manufacturer.MAN_CITY,
                  manufacturer.MAN_COUNTYSTATEPROVINCE,
                  manufacturer.MAN_COUNTRY,
                  manufacturer.MAN_POSTCODE,
                ]}
              />
              <br></br>

              {/* Manufacturer Customer Service Email */}
            </Grid>
            <Grid item xs>
              {/* UK Responsible Person */}

              {manufacturer.REP_NAME ? (
                <div>
                  <Typography
                    variant="p"
                    component="p"
                    className="text-align-left-bold"
                  >
                    UK Responsible Person/Northern Ireland Authorised
                    Representative:
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    className="text-align-left"
                  >
                    {manufacturer.REP_NAME}
                  </Typography>
                  <br />
                </div>
              ) : (
                <div data-testid="nullRepName"></div>
              )}

              {/* Relationship of responsible person */}

              {manufacturer.RELATIONSHIP ? (
                <div>
                  <Typography
                    variant="p"
                    component="p"
                    className="text-align-left-bold"
                  >
                    Relationship:
                  </Typography>

                  <Typography variant="p" component="p">
                    {manufacturer.RELATIONSHIP}
                  </Typography>
                  <br />
                </div>
              ) : (
                <div data-testid="nullRelationship"></div>
              )}

              {/* Representative address */}

              {manufacturer.REP_ADDRESS_LINE_1 ? (
                <div>
                  <Typography
                    variant="p"
                    component="p"
                    className="text-align-left-bold"
                  >
                    Representative Address:
                  </Typography>
                  <Address
                    addressDetails={[
                      manufacturer.REP_ADDRESS_LINE_1,
                      manufacturer.REP_ADDRESS_LINE_2,
                      manufacturer.REP_ADDRESS_LINE_3,
                      manufacturer.REP_ADDRESS_LINE_4,
                      manufacturer.REP_CITY,
                      manufacturer.REP_COUNTY_STATE_PROVINCE,
                      manufacturer.REP_COUNTRY,
                      manufacturer.REP_POSTCODE,
                    ]}
                  />
                </div>
              ) : (
                <div data-testid="nullRepAddress"></div>
              )}
              <br></br>

              {/* Representitive customer service number */}
            </Grid>
          </Grid>

          <Typography
            variant="p"
            component="p"
            className="text-align-right"
            onClick={handleShowMoreClicked}
          >
            <Button id="show-less-button" data-cy="showLessButton">
              Show Less
            </Button>
          </Typography>
        </div>
      )}
    </div>
  );
}

export default ManufacturerResult;
